<template>
  <div>
    <div class="indexUser">
      <div>
        <!-- header  点击 -->
        <headers :userList="userList"></headers>
      </div>
      <div class="bg1 userBox">
        <div class="wrap bgw userAddress">
          <div class="title">新增收货地址</div>
          <div class="box">
            <div>
              <p class="fl">姓名</p>
              <input placeholder="请输入姓名" v-model="name" class="text" type="text" />
            </div>
            <div class="addresss">
              <p class="fl">地区</p>
              <div @mouseover="addType = 1" @mouseout="addType = 0" class="addresList">
                <p v-show="add1 == '' ">请选择地址</p>
                <p  v-show="add1 != '' ">{{add1}} {{add2}} {{add3}}</p>
                <div v-show="addType == 1 && isshow" >
                  <ul class="a1">
                    <li
                      :class="{active:act1 == i}"
                      @click="aTab1(v,i)"
                      v-for="(v,i) in addressList1"
                      :key="i"
                    >{{v.name}}</li>
                  </ul>
                  <ul v-show="addressList2!='' " class="a2">
                    <li
                      :class="{active:act2 == i}"
                      @click="aTab2(v,i)"
                      v-for="(v,i) in addressList2"
                      :key="i"
                    >{{v.name}}</li>
                  </ul>
                  <ul v-show="addressList3!='' " class="a3">
                    <li
                      :class="{active:act3 == i}"
                      @click="aTab3(v,i)"
                      v-for="(v,i) in addressList3"
                      :key="i"
                    >{{v.name}}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="details">
              <p class="fl">详细地址</p>
              <textarea v-model="addDetails"></textarea>
            </div>
            <div>
              <p class="fl">手机号</p>
              <input maxlength="11" v-model="phone" />
            </div>
            <!-- <div>
                <p class="fl" style="width:150px"></p>
                <span></span> 设为默认地址
            </div>-->
            <div>
              <p class="fl"></p>
              <button @click="checking()">确定</button>
            </div>
          </div>
          <!-- 地址  列表 -->
          <div class="list">
            <div class="title">
              <div>收货人</div>
              <div>所在地区</div>
              <div>详细地址</div>
              <div>手机号</div>
              <div>操作</div>
            </div>
            <div v-for="(v,i) in list " :key="i">
              <div>{{v.user_name}}</div>
              <div class="txt">
                <p class="two">{{v.city_name}} {{v.area_name}}</p>
              </div>
              <div class="txt">
                <p class="two">{{v.address}}</p>
              </div>
              <div>{{v.mobile}}</div>
              <div>
                <span class="set" @click="editDizhi(v)">操作</span>
                <span @click="remove(v)">删除</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <publicBottom></publicBottom>
      </div>
      <div class="editAddress" v-if="show" @mousewheel.prevent>
        <div class="content">
          <div class="title">
            <span class="fl">收货地址</span>
            <img
              class="fr"
              @click="closeEditAddress"
              src="@/assets/image/Popup_Close_icon@2x.png"
              alt
            />
          </div>
          <div class="item item1">
            <span>所在地区</span>
            <div @mouseover="addType = 1" @mouseout="addType = 0" class="addresList">
              <p v-show="add1 == '' ">请选择地址</p>
              <p class="font_size" v-show="add1 != '' ">{{add1}} {{add2}} {{add3}}</p>
              <div v-show="addType == 1">
                <ul class="a1">
                  <li
                    :class="{active:act1 == i}"
                    @click="aTab1(v,i)"
                    v-for="(v,i) in addressList1"
                    :key="i"
                  >{{v.name}}</li>
                </ul>
                <ul v-show="addressList2!='' " class="a2">
                  <li
                    :class="{active:act2 == i}"
                    @click="aTab2(v,i)"
                    v-for="(v,i) in addressList2"
                    :key="i"
                  >{{v.name}}</li>
                </ul>
                <ul v-show="addressList3!='' " class="a3">
                  <li
                    :class="{active:act3 == i}"
                    @click="aTab3(v,i)"
                    v-for="(v,i) in addressList3"
                    :key="i"
                  >{{v.name}}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="item">
            <span>收货人</span>
            <input v-model="editAddress.user_name" placeholder="请输入收货人" />
          </div>
          <div class="item">
            <span>联系电话</span>
            <input type="number" v-model="editAddress.mobile" placeholder="请输入联系电话" />
          </div>
          <div class="item">
            <span>详细地址</span>
            <textarea type="textarea" v-model="editAddress.address" placeholder="请输入详细地址"></textarea>
          </div>
          <button @click="savaAddress">保存</button>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>确定要删除当前收货地址</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="removes()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import headers from "./header"; // 头部 TOP 和header
import publicBottom from "@/components/public/public_bottom"; //底部

import { usergetinfo } from "@/request/user";
import { regionData, CodeToText } from "element-china-area-data"; // 自定义 地图
import { user_address_List } from "@/request/user"; // 地址列表
import { user_address_Add } from "@/request/user"; // 添加地址
import { getAreaLista } from "@/request/user"; // 新增地址列表
import { user_address_del } from "@/request/user"; //删除地址
import { updataAddress } from "@/request/user"; // 修改地址
export default {
  components: {
    headers,
    publicBottom
  },
  data() {
    return {
      dialogVisible: false,
      options: regionData,
      selectedOptions: [],
      removeData: [], //删除地址
      name: "", // 姓名
      addDetails: "", // 详细 收货地址
      phone: "", // 手机号
      list: [], //收货 地址
      addressList1: [],
      addressList2: [],
      addressList3: [],
      addType: 0,
      act1: -1,
      act2: -1,
      act3: -1,
      add1: "",
      add2: "",
      add3: "",
      sendAdd: "", // 提交给 后台的 数字
      userList: [], // 个人信息
      editAddress: {},
      show: false,
      isshow: true
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }

    this.init();
    this.start();
    this.staetList();
    // this.state=this.$route.query.state
  },
  methods: {
    removes() {
      this.dialogVisible = false;
      user_address_del({
        token: this.$tokens,
        id: this.removeData.id
      }).then(res => {
        if (res.code == 1) {
          this.$message({
            message: "删除成功",
            type: "success"
          });
          this.start();
        }
      });
    },
    remove(v) {
      this.removeData = v;
      this.dialogVisible = true;
    },
    aTab1(v, i) {
      this.add1 = v.name;
      this.add2 = "";
      this.act2 = -1;
      this.act3 = -1;
      this.add3 = "";
      this.sendAdd = "";
      this.addressList3 = [];
      this.act1 = i;
      getAreaLista({
        pid: v.id
      }).then(res => {
        this.addressList2 = res.data;
      });
    },
    aTab2(v, i) {
      this.add2 = "- " + v.name;
      this.add3 = "";
      this.act3 = -1;
      this.sendAdd = "";
      this.act2 = i;
      getAreaLista({
        pid: v.id
      }).then(res => {
        this.addressList3 = res.data;
      });
    },
    aTab3(v, i) {
      this.add3 = "- " + v.name;
      this.act3 = i;
      this.sendAdd = v.id;
    },
    checking() {
      if (this.name == "") {
        this.$message.error("请输入 姓名");
      } else if (this.address == "") {
        this.$message.error("请选择 地址");
      } else if (this.addDetails == "") {
        this.$message.error("请填写 详细地址");
      } else if (this.phone == "") {
        this.$message.error("手机号不能为空");
      } else if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$message.error("手机号格式不正确");
      } else {
        this.send();
      }
    },
    send() {
      user_address_Add({
        token: this.$tokens,
        user_name: this.name,
        mobile: this.phone,
        area_id: this.sendAdd,
        address: this.addDetails
      }).then(res => {
        if (res.code == 1) {
          this.$message({
            message: "操作成功",
            type: "success"
          });
          this.add1 = "";
          this.add2 = "";
          this.add3 = "";
          this.name = "";
          this.phone = "";
          this.sendAdd = "";
          this.addDetails = "";
          this.start();
        }
      });
    },
    start() {
      user_address_List({
        token: this.$tokens
      }).then(res => {
        this.list = res.data.data;
      });
    },
    editDizhi(v) {
      this.show = true;
      this.isshow = false;
      this.editAddress = { ...v };
      this.add1 = v.province_name;
      this.add2 = v.city_name;
      this.add3 = v.area_name;
      this.sendAdd = v.area_id;
    },
    savaAddress() {
      if (this.editAddress.user_name == "") {
        this.$message.error("请输入 姓名");
      } else if (this.editAddress.address == "") {
        this.$message.error("请填写 详细地址");
      } else if (this.editAddress.mobile == "") {
        this.$message.error("手机号不能为空");
      } else if (!/^1[3456789]\d{9}$/.test(this.editAddress.mobile)) {
        this.$message.error("手机号格式不正确");
      } else {
        this.sendSavaAddress();
      }
    },
    sendSavaAddress() {
      updataAddress({
        token: this.$token,
        id: this.editAddress.id,
        realname: this.editAddress.user_name,
        mobile: this.editAddress.mobile,
        area_id: this.sendAdd,
        address: this.editAddress.address
      }).then(res => {
        if (res.code == 1) {
          this.$message.success("修改成功");
          this.start();
          this.closeEditAddress();
        }
      });
    },
    closeEditAddress() {
      this.show = false;
      this.isshow = true;
      this.add1 = "";
      this.add2 = "";
      this.add3 = "";
      this.act1 = -1;
      this.act2 = -1;
      this.act3 = -1;
    },
    staetList() {
      getAreaLista({
        pid: 1
      }).then(res => {
        this.addressList1 = res.data;
      });
    },
    handleChange(e) {
      let arr = [];
      for (let i = 0; i < e.length; i++) {
        let num = e[i];
        num = Number(num);
        arr.push(num);
      }

      this.address = arr;
      var msg =
        CodeToText[arr[0]] +
        "-" +
        CodeToText[arr[1]] +
        "-" +
        CodeToText[arr[2]];
    },
    init() {
      //   个人信息
      usergetinfo({
        token: this.$tokens
      }).then(res => {
        this.userList = res.data;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.addresList {
  margin-left: 96px;
  width: 400px;
  height: 47px;
  line-height: 47px;
  font-size: 18px;
  text-indent: 10px;
  border: 1px solid #a8a8a8 !important;
  position: relative;
  background: white;
  p {
    width: 100% !important;
    font-family: "微软雅黑";
  }
  ul {
    background: white;
    position: absolute;
    top: 47px;
    width: 133px;
    height: 300px;
    border: 1px solid#a8a8a8;
    z-index: 2;
    overflow-y: auto;
  }
  li {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    overflow: hidden;
  }
  li:hover {
    color: #4eeec8;
  }
  .active {
    color: #4eeec8;
  }
  .a1 {
    left: 0;
  }
  .a2 {
    left: 133px;
  }
  .a3 {
    left: 266px;
  }
}
.list {
  font-size: 18px;
  padding-left: 34px;
  padding-right: 34px;
  padding-bottom: 60px;
  .title {
    border-top: 1px solid #e6e6e6;
    height: 66px;
    background: #f6f6f6;
    text-indent: 0px;
    div {
      height: 66px;
      line-height: 66px;
      width: 20%;
      text-align: center;
      border: none !important;
    }
  }
  .txt {
    padding-top: 30px;
    line-height: 26px;
  }
  > div {
    height: 92px;
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    div {
      border-left: 1px solid #e6e6e6;
      height: 92px;
      line-height: 92px;
      width: 20%;
      text-align: center;
    }
    div:nth-of-type(5) {
      border-right: 1px solid #e6e6e6;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  span {
    display: inline-block;
    width: 50%;
    text-align: center;
    color: #ee4e4e;
  }
  .set {
    color: #4eeec8;
  }
}
.box {
  padding-top: 40px;
  padding-left: 88px;
  padding-bottom: 40px;
  font-size: 22px;
  button {
    width: 174px;
    height: 52px;
    font-size: 22px;
  }
  .text {
    font-weight: 0;
    font-family: "微软雅黑";
    color: #444;
    font-size: 18px;
  }
  > div {
    margin-bottom: 37px;
    height: 47px;
    position: relative;
  }
  span {
    display: block;
    border-radius: 50%;
    left: 0;
    width: 28px;
    height: 28px;
    border: 1px solid #a8a8a8;
    position: absolute;
    left: 100px;
    top: 0;
  }
  .addresss {
    input {
      text-indent: 0;
    }
  }
  .details {
    height: 220px;
  }
  textarea {
    height: 200px;
    width: 400px;
    //  padding-top: 20px;
    text-indent: 10px;
    font-size: 18px;
    resize: none;
    font-family: "微软雅黑";
    padding: 10px;
  }
  p {
    height: 47px;
    line-height: 47px;
    width: 96px;
  }
  input {
    width: 400px;
    height: 47px;
    font-size: 22px;
    text-indent: 10px;
    border: 1px solid #a8a8a8 !important;
  }

  .userAddress {
    border: 1px solid #e6e6e6;
  }
}
.title {
  height: 69px;
  line-height: 69px;
  font-size: 18px;
  text-indent: 36px;
  border-bottom: 1px solid #e6e6e6;
}
.editAddress {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  .content {
    width: 550px;
    height: 350px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -275px;
    margin-top: -175px;
    .title {
      box-sizing: border-box;
      padding-top: 10px;
      height: 40px;
      background: #eee;
      span {
        margin-left: 5px;
        margin-top: -24px;
      }
      img {
        width: 15px;
        height: 15px;
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .item1 {
      width: 400px !important;
      height: 50px !important;
      .font_size{
        font-size: 14px;
      }
      span {
        margin-left: -95px;
        display: inline-block;
      }
      .addresList {
        height: 30px;
        line-height: 30px;
      }
    }
    .item {
      height: 40px;
      width: 400px;
      margin: 8px 0px;
      margin-left: 20px;
      span {
        display: inline-block;
        width: 80px;
        text-align-last: justify;
        margin-right: 20px;
        height: 30px;
        line-height: 30px;
      }
      input {
        border: 1px solid #c0c4cc !important;
        height: 30px;
        line-height: 30px;
        width: 200px;
        border-radius: 5px;
        padding-left: 10px;
      }
      .addresList {
        width: 300px;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        margin-left: 0px;
        font-size: 16px !important;
        border-radius: 5px;
        div {
          margin-top: -30px;
          ul {
            top: 40px;
          }
        }
      }
      textarea {
        width: 250px;
        height: 80px;
        font-size: 14px;
        vertical-align: middle;
        border: 1px solid #c0c4cc !important;
        resize: none;
        padding: 8px;
        border-radius: 5px;
        font-weight: 500;
        color: black;
        font-family: Arial;
      }
    }
    button {
      position: absolute;
      padding: 5px 22px;
      border: 1px solid red;
      background: #fff;
      color: #f00000;
      bottom: 10px;
      left: 230px;
      border-radius: 5px;
      cursor: pointer;
      outline: none;
    }
  }
}
</style>